import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [{
    path: '/index',
    component: Index,
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    },
    component: () => import( /* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    },
    component: () => import( /* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/funct',
    name: 'funct',
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    },
    component: () => import( /* webpackChunkName: "product" */ '../views/funct.vue')
  },
  {
    path: '/successfulCase',
    name: 'SuccessfulCase',
    meta: {
      hidden: true,
      title: "上海恒裕华网络科技有限公司"
    },
    component: () => import( /* webpackChunkName: "successfulCase" */ '../views/SuccessfulCase.vue')
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, ) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return {
        x: 0,
        y: 0
      }
    }

  }
})

export default router