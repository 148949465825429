<template>
  <div>
    <div v-if="type==1">
      <div class="tabbox">
        <div class="logo">
          <img src="../assets/image/logonew.png" alt="">
          <span>上海恒裕华网络科技有限公司</span>
        </div>

        <el-menu :default-active="activeIndex" class="el-menu-demo" style="border-bottom: none;" background-color="#fff"
          text-color="#000" active-text-color="#e84554" mode="horizontal">
          <el-menu-item class="menu" index="#item1" @click="jup('item1')">首页</el-menu-item>
          <el-menu-item class="menu" index="#item2" @click="jup('item2')">商城</el-menu-item>
          <el-menu-item class="menu" index="#item3" @click="jup('item3')">服务案例</el-menu-item>
          <el-menu-item class="menu" index="#item4" @click="jup('item4')">市场资讯</el-menu-item>
        </el-menu>

      </div>
      <div class="home-box">
        <img src='../assets/img/bl.jpg' alt="">
      </div>
      <!-- <div class="home-box"> -->
      <!--  轮播图 -->
      <!-- <div class="section1" id="item1">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div v-for="item of swiperList" :key="item.id" class="swiper-slide">
              <img class="swiper-img" :src="item.imgUrl" alt="">
            </div>
          </div> -->
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev swiper-button-white"></div>
          <div class="swiper-button-next swiper-button-white"></div>
        </div>
      </div> -->
      <div class="shangc" id="item2">
        <h2>商城</h2>
        <div class="isopn">
          <div class="isopsdn" v-for="(item,key) in goods" :key='key'>
            <img :src="item.url" alt="" @click="toopens(key+1)">
          </div>
        </div>
      </div>

      <div class="items3" id="item3">
        <h2>服务案例</h2>
        <img src='../assets/img/item.jpg' alt="">
      </div>

      <div class="items4" id="item4">
        <h2>市场资讯 </h2>
        <div class="isbox4">
          <div class="items41 flex2">
            <img src='../assets/img/cons1.jpg' alt="" @click="totext(1)">
          </div>
          <div class="items42 flex2">
            <img src='../assets/img/cons2.jpg' alt="" @click="totext(2)">
          </div>
          <div class="items43 flex3">
            <img src='../assets/img/cons3.jpg' alt="" @click="totext(3)">
            <img src='../assets/img/cons4.jpg' alt="" @click="totext(4)">
          </div>
        </div>
      </div>
      <div class="items3">
        <img src='../assets/img/about.jpg' alt="">
      </div>
      <vue-bfooter></vue-bfooter>
    </div>
    <div v-if="type==2" class="isbox2">
      <div class="openfan">
        <span class="fan1" @click="type=1">《 返回</span>
        <span class="fan2">商品详情</span>
      </div>
      <!-- <div @click="opencon()" class="saomasih">dfasdfasfdsad</div> -->
      <!-- <span class="fan1" @click="type=1"></span> -->
      <div class="isimgl">
        <div @click="opencon()" class="saomasih"></div>
        <img :src="gooditem" alt="" class="isimgdetail">
      </div>

      <el-dialog title="扫码支付" :visible.sync="dialogVisible" width="150px" :before-close="handleClose">
        <div>
          <img src="../assets/image/andrord.png" alt="" class="codema">
        </div>
      </el-dialog>
      <!-- <div @click="type=1" class="openfan">
        <span class="fan1" @click="type=1">《 返回</span>
        <span class="fan2">商品详情</span>
      </div>
      <div class="isbox2item">
        <div class="tops flex-box">
          <div class="left flex1">
            <img src="../assets/img/goods1.jpg" alt="">
          </div>
          <div class="right flex1">
            <p>￥100</p>
            <p>美妍集臻宠焕妍系列</p>
            <div @click="opencon()">扫码支付</div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="type==3" class="isbox3">
      <div class="box3deng">
        <span class="fan1" @click="blacktohome()">《 返回</span>
        <span class="fan2">市场资讯</span>
      </div>
      <div class="isbox333">
        <img :src="gooditem" alt="" class="isimgdetail">
      </div>
    </div>

  </div>
  <!-- </div> -->
</template>
<script>
import bfooter from './components/b-footer'
import Swiper from 'swiper'
export default {
  name: 'index',
  components: {
    'vue-bfooter': bfooter,
  },
  data () {
    return {
      dialogVisible: false,
      gooditem: 1,
      goods: [
        { url: require('../assets/img/goods1.jpg'), id: 1 },
        { url: require('../assets/img/goods2.jpg'), id: 2 },
        { url: require('../assets/img/goods3.jpg'), id: 3 },
        { url: require('../assets/img/goods4.jpg'), id: 4 },
        { url: require('../assets/img/goods5.jpg'), id: 5 },
        { url: require('../assets/img/goods6.jpg'), id: 6 },
        { url: require('../assets/img/goods7.jpg'), id: 7 },
        { url: require('../assets/img/goods8.jpg'), id: 8 },
        { url: require('../assets/img/goods9.jpg'), id: 9 },
        { url: require('../assets/img/goods10.jpg'), id: 10 },
      ],
      type: 1,
      activeIndex: '#item1',
      // 轮播，使用变量循环
      swiperList: [
        {
          id: '001',
          imgUrl: require('../assets/image/baner1.png')
        },
        {
          id: '002',
          imgUrl: require('../assets/image/baner2.png')
        },
        {
          id: '003',
          imgUrl: require('../assets/image/baner3.png')
        },
        {
          id: '004',
          imgUrl: require('../assets/image/baner4.png')
        },
      ],
      section2: [
        { id: 1, url: require('../assets/image/btf-1.png'), urls: require('../assets/image/sy1.png'), urli: require('../assets/image/sy11.png'), title: '美容管理' },
        { id: 2, url: require('../assets/image/beau-1.png'), urls: require('../assets/image/sy2.png'), urli: require('../assets/image/sy22.png'), title: '美容美体' },
        { id: 3, url: require('../assets/image/Beauty-1.png'), urls: require('../assets/image/sy3.png'), urli: require('../assets/image/sy33.png'), title: '皮肤管理' },
        { id: 4, url: require('../assets/image/px-1.png'), urls: require('../assets/image/sy4.png'), urli: require('../assets/image/sy44.png'), title: '美容培训' },
        { id: 5, url: require('../assets/image/cosmetics-1.png'), urls: require('../assets/image/sy5.png'), urli: require('../assets/image/sy55.png'), title: '化妆造型' },
        { id: 6, url: require('../assets/image/slimming-1.png'), urls: require('../assets/image/sy6.png'), urli: require('../assets/image/sy66.png'), title: '瘦身纤体' },
      ],
      section4: [
        {
          url: require('../assets/image/sect4-111.png'), list: [
            { title: '端口业务化', text: '各端口各功能模块都是按照直营体系独一无二的业务模式、 业务流程及工作内容打造及呈现的。' },
            { title: '数据业务化', text: '系统所有呈现出来的数据都是为了更精准的指导业务、服务于业务。' },
            { title: '财务业务化', text: '财务管理完全是业务型财务管理，除了管好钱管好货，还要把卖卡管规范， 把业绩结构管合理管健康，保证不乱卖不卖乱。' },
            { title: '模式业务化', text: '系统基于28年直营连锁美容院业务模式、管理经验精雕细琢，确保顾客价值 提升、员工价值提升、店价值提升，打造盈利多、价值高、时间久的产业互联网美容院。' }
          ],
        },
        {
          url: require('../assets/image/sect4-222.png'), list: [
            { title: '在线商城', text: '支持顾客24小时线上参与营销活动、购买商品、查询订单。 ' },
            { title: '在线查阅', text: 'APP支持员工及管理层、店老板随时查阅顾客档案、预约、线上线下销售 消耗信息。' },
            { title: '在线培训', text: '支持员工在线通过员工天地内图片、文档、视频、微课堂学习专业知识，提升 专业技能，以便更好的为顾客服务并提升销售能力。' },
            { title: '在线指导', text: '管理层、店老板通过APP四表四看模块及架构模块，实时精准指导各层级 开展各项业务工作，打造高价值美容院体系。' },
          ]
        },
        {
          url: require('../assets/image/sect4-333.png'), list: [
            { title: '一键问题顾客查询', text: '六类问题顾客一键查询，构建动态精准的高粘性会员管理体系，提高顾客 管理效率，精准提升顾客价值。 ' },
            { title: ' 一键沉睡顾客激活', text: '一键激活沉睡顾客，提升顾客返店率，保障顾客不流失。' },
            { title: '一键员工绩效查阅', text: '一键掌握员工三同步指标完成进度，员工预约人次数、加项目加产品以 及客回放指标，打造高价值员工体系。' },
            { title: '一键预约自动排班', text: '通过APP一键有目标有针对性的精准在线预约，实时五约提醒，合理 安排服务时间，提升预约效率。' },
          ]
        },
        {
          url: require('../assets/image/sect4-444.png'), list: [
            { title: '智能收银', text: ' 更便捷更高效的智能收银管理系统。极速开单收银、挂单结算、办卡充值、 跨店消费、智能核销、电子签名等，简单几个按键实现门店消费、消耗全场景覆盖' },
            { title: '智能管店', text: '员工、院长及管理干部通过四表四看模块，按照连锁直营美容院经营模式 及工作流程，流程化标准化精准的完成每天的各项工作。 ' },
            { title: '智能拓客', text: '扫码拓客、注册有礼带新客、优惠券分享拓新客、秒杀分享拓新客、推荐 有礼带嘉宾、限时拼团带嘉宾等多种零成本拓客方式，快速获得精准的完成每天的各项工作。 ' },
            { title: '智能分析', text: '门店经营数据深度实时动态精准呈现，管理层运用数据精准决策，打造高 价值美容院体系。  ' },
          ]
        },
        {
          url: require('../assets/image/sect4-555.png'), list: [
            { title: '多端同步', text: '美业汇系统五个端口财务数据和业务数据完全打通，实现财务数据和业务 数据一体化，确保各项经营数据实时精准的呈现在各端口及各功能模块中提升业绩用。' },
            { title: '多店管理', text: '多店多端财务数据和业务数据一体化，动态实时同步，权限分层分岗位 设置，多店连锁管控，降低层级管理难度，高效便捷，成倍提升管理效率确保提升业绩 ' },
            { title: '多维联动', text: '营销三维度、管理四维度、时间五维度、会议六维度、业务动作各层级实 时可见，确保精准指导各业务层高效提升业绩。' },
            { title: '立体专属', text: '顾客、员工、管理层、店老板、门店五位一体化立体呈现在美业汇系统中， 是一款独一无二深耕美业的专属美业产业互联网店务管理系统。' },
          ]
        },
      ],
      swiper7: [
        { url: require('../assets/image/an2.png') },
        { url: require('../assets/image/anl.png') },
        { url: require('../assets/image/anl1.png') },
      ],
      swiperList1: [
        {
          id: '001',
          imgUrl: require('../assets/images/a1.png')
        },
        {
          id: '002',
          imgUrl: require('../assets/images/a2.png')
        },
        {
          id: '003',
          imgUrl: require('../assets/images/a3.png')
        },
      ],
      sect2: 0,
      sect4: 0,
      input: '',
      centerDialogVisible: false,
      ruleForm: {
        realname: '',
        mobile: '',
        code: ''
      },
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },

        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    }
  },
  mounted () {
    new Swiper('.swiper1', {
      loop: true,
      // 如果需要分页器
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: 3500,
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    })
    new Swiper('.swiper2', {
      loop: true,
      // 如果需要分页器
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: 3500,
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      pagination: '.swiper-pagination2',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    })
  },
  watch: {
  },
  methods: {
    blacktohome () {
      this.type = 1
      setTimeout(() => {
        this.jup('item4')
      }, 1)

    },
    handleClose () {
      this.dialogVisible = false
    },
    opencon () {
      console.log(123);
      this.dialogVisible = true
    },
    totext (val) {
      this.type = 3
      this.gooditem = require('../assets/img/sh' + val + '.jpg')
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    toopens (val) {
      this.type = 2
      this.gooditem = require('../assets/img/detail' + val + '.jpg')
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    jup (val) {
      const anchor = document.getElementById(val)
      anchor.scrollIntoView()
    },
    section2Enter (key) {
      this.sect2 = key
    },
    section4Enter (key) {
      this.sect4 = key
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('发送成功');
          this.sub()
        }
      });
    },
    open () {
      this.centerDialogVisible = true
      this.ruleForm.mobile = this.input
    },
    sub () {
      var data = this.ruleForm
      console.log(data);

      this.$http.submit(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
        this.centerDialogVisible = false
      })

    },
    submit () {
      var data = {}
      data.mobile = this.ruleForm.mobile
      this.$http.feilogin(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
      })
    },
    tonews () {
      this.$router.push({
        path: '/news',
      })
    }
  }
}
</script>
<style lang="scss">
@import "../assets/index.scss";
@import "../assets/nav.scss";
</style>
